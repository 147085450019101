import config from 'CONFIG';
import { useEffect } from 'react';
import analytics from '#utils/analytics.ts';
import { scrollToTop } from '#utils/scroll.ts';

const Analytics = () => {
  useEffect(() => {
    const isHyperLinkedPage = Boolean(location.hash);
    if (config.ANALYTICS) analytics.init();
    if (!isHyperLinkedPage && !config.DEV) scrollToTop();
    const currentHost = window.location.hostname;
    const verificationHash =
      config.GOOGLE_VERIFICATION_HASH[
        currentHost as keyof typeof config.GOOGLE_VERIFICATION_HASH
      ] ?? config.GOOGLE_VERIFICATION_HASH.default;

    if (verificationHash) {
      const existingMetaTag = document.querySelector(`meta[name="google-site-verification"]`);
      if (!existingMetaTag) {
        const metaTag = document.createElement('meta');
        metaTag.name = 'google-site-verification';
        metaTag.content = verificationHash;
        document.head.appendChild(metaTag);
      }
    }
  }, []);
  return '';
};

export default Analytics;
